/*Color variables*/
/*gray variables*/
/*white variables*/
/*black variables*/
@media (min-width: 1025px) {
  .boxed .page {
    width: 1170px;
    margin: 0 auto;
    background: #f2f6f9;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 0px 0px, rgba(0, 0, 0, 0.3) 0px 5px 25px 0px;
  }
  .boxed .header.top-header {
    width: 1170px !important;
  }
  .boxed .hor-menu.horizontal-main {
    max-width: 1170px;
  }
  .boxed .main-content .container,
.boxed .main-content .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
  }
  .boxed .main-header > .container,
.boxed .main-header > .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .boxed .app-sidebar {
    left: auto !important;
  }
  .boxed .side-header {
    left: auto !important;
    right: auto !important;
    z-index: 1044;
  }
  .boxed .main-header.side-header {
    max-width: 1170px;
  }
  .boxed .nav-tabs .nav-link {
    padding: 1rem 1.2rem;
  }
  .boxed.app.sidenav-toggled .app-sidebar.comb-sidebar {
    left: -230px !important;
  }
  .boxed .horizontalMenu > .horizontalMenu-list > li:last-child > ul.sub-menu {
    right: 0;
  }
  .boxed .page-style1 .page-content {
    z-index: 999;
  }
  .boxed .app-header {
    padding-right: 10px !important;
  }
  .boxed .second-sidemenu {
    left: auto !important;
    margin-left: 70px;
  }
  .boxed.app.sidebar-mini.sidenav-toggled4 .first-sidemenu {
    left: auto !important;
  }
  .boxed .profile-dropdown {
    padding-right: 0;
  }

  body.boxed {
    background: #bdc0c8 !important;
  }
}
@media only screen and (max-width: 1162px) and (min-width: 992px) {
  .boxed .hor-menu .horizontalMenu > .horizontalMenu-list > li > a {
    font-size: 12px;
    padding: 10px 5px 10px 5px !important;
  }
}/*# sourceMappingURL=boxed.css.map */